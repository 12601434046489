import React from "react";
import { useState, useEffect } from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import HeroImages from "../components/Reusables/HeroImages";
import donate from "../images/Donate/New__Donate__page_on_upReach_website.png"
import logo from "../images/Donate/FR_Fundraising_Badge.png"
import donateteam from "../images/Donate/Donate_Team.png"
import { Link } from "gatsby";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import axios from "axios";
import StatisticBox from "../components/Home/StatisticBlock";

const StyledWrapper = styled.div`
.apply-now {
  padding: 5% 0;
  padding-left: 20px;
  padding-right:20px;
  background-color: white;
  text-align: center;
  font-size: 1rem;
  font-family: 'Nunito Sans',sans-serif;
  @media (max-width: 600px) {
    font-size: 1rem;
  font-family: 'Nunito Sans',sans-serif;
  }
}
.hero-img-logo {
  text-align: center;
  margin-bottom: -97px;
  margin-top:30px;
}
.stats{
  margin-top:-37px;
}
  
.internal-links-a-tag.donate{
  background-color: #464FD0;
  padding-left : 10px;
  padding-right : 10px;
  padding-top : 9px;
  padding-bottom : 9px;
  color: white;
  font-weight: bold;
  text-decoration: none;
}

.donate-text {
  background: white;
  font-weight: bold;
  font-size: 30px;
  text-transform: uppercase;
  @media (max-width: 600px) {
    font-size: 15px;
    font-weight: bold;
  }
}
.page {
  background: white;
}

  .enthuse-page{
    height:1000px;
    border:none;
  }
  .enthusePage{
    position : relative;
    padding-bottom: 80px;
  }
  .content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%', // Adjust the width of the modal
    height: '80%', // Adjust the height of the modal
  },
`;


const fetchStats = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/stats`);
};

const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Donate_Page />
    </QueryClientProvider>
  );
}

const Donate_Page = () => {
  const [statsData, setStatsData] = useState(null);

  const { data: stats } = useQuery("Donate_Page", fetchStats);
  useEffect(() => {
  if (stats !== undefined) {
    setStatsData(stats.data);
  }
})
useEffect(() => {
  /* eslint-disable */
  !function (e, t, c) {
    var a, r = e.getElementsByTagName(t)[0];
    e.getElementById(c) || ((a = e.createElement(t)).id = c,
    a.src = "https://app.enthuse.com/bundles/scripts/iframe?d=" + Date.now(),
    r.parentNode.insertBefore(a, r));
  }(document, "script", "checkout-js");
}, [])

  return (
    <Layout>
      <StyledWrapper>
        <div className="page">
          {/* <HeroImages donate = {true}  heroImage={donate} /> */}
          <div className="apply-now container-fluid">
            <p>We support undergraduates from lower socio-economic backgrounds to realise their full career potential and secure top graduate jobs.</p>
            <p>Together with our donors, we are transforming the UK job market - one young person at a time.</p> 
            <p><b>We cannot exist without your support.</b></p>
          </div>
          <div className="enthusePage">
          <iframe
            title="Enthuse Donation Page"
            src="https://upreach.enthuse.com/donate" // Replace with the actual URL
            width="100%"
            frameborder="0" 
            scrolling="no"
            height="500px" 
            onload="iframeLoaded()"
            id="checkout-iframe"
            className="enthuse-page"
          ></iframe>
          </div>
          <div className="container-fluid page report">
            <div className="row mission-row">
              <div className="col-12 col-md-6 flex-column justify-content-center padding-left-8 padding-right-8 mission order-2 order-md-1 margin-top-100">
                <p className="donate-text">WANT TO KNOW HOW YOUR DONATION CAN MAKE A DIFFERENCE?</p>
                <p>Our 2022 Impact Report details how we’re working tirelessly to make a positive difference in the lives of the young people who take part in our programmes.</p>
                <div>
                  <Link
                      activeClassName="active"
                      partiallyActive={true}
                      className="internal-links-a-tag donate"
                      to={"https://www.paperturn-view.com/uk/upreach/upreach-impact-report?pid=MjY260134"}
                      target="_blank"
                    >
                      VIEW REPORT
                    </Link>
                </div>
              </div>
              <div className="col-12 col-md-6 order-1 order-md-1">
                <HeroImages donate = {true}  heroImage={donateteam} /> 
              </div>
            </div>
          </div>
          <div className="stats">
          {
            statsData &&
            statsData.length > 0 &&
            statsData.map(
              item =>
                item.slug.toLowerCase() === "Donate Page".toLowerCase() &&
                item.StudentStatistics.length > 0 && (
                  <StatisticBox donate={true} statistic={item.StudentStatistics} />
                )
              )
          }
          </div>
          <div className="hero-img-logo">
            <img  src={logo} alt="hero-image" />
          </div>
        </div>
      </StyledWrapper>
    </Layout>
  );
};
