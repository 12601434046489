import React from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  .hero-img {
    width: 100%;
    height: 30vw;
    object-fit: cover;
    object-position: center;
    @media (max-width: 600px) {
      height: 55vw;
    }
  }
  .hero-img-donate{
    width: 100%;
    height: 36vw;
    object-fit: cover;
    object-position: center;
    @media (max-width: 600px) {
      height: 55vw;
    }
  }
`;

const HeroImages = (props) => {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper>
      {props.heroImage.Image !== null && (
        !props.donate ?
        <img className="hero-img" src={mainUrl + props.heroImage} alt="hero-image" />
        :
        <img className="hero-img-donate" src={props.heroImage} alt="hero-image" />
      )}
    </StyledWrapper>
  );
};

export default HeroImages;
